/* eslint-disable no-nested-ternary */
import { useUserContext } from "components/context/UserContext";
import { useState } from "react";
import { getMagnetPageLinkWithName } from "utils/helpers";
import BrandIcon from "assets/Images/BrandsIcon.png";
import DesktopSidebarImageCard from "components/atoms/sidebar/DesktopSidebarImageCard";
import { useBrandContext } from "components/context/BrandContext";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import DummyProfileImage from "assets/Images/DummyProfileImage.svg";
import { useMatch } from "react-router-dom";
import { useWalkthroughContext } from "pages/UserProfile/walkthrough/WalkthroughContext";
import clsx from "clsx";
import useSideBarStore from "components/store/sidebarStore";
import useNotificationStore from "globalstore/notificationStore";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useDeviceType, useRouter } from "../../hooks";
import ResusableSidebar from "../../atoms/forms/Sidebar";
import {
  BadgeIcon,
  MagnetIcon,
  FridgeIcon,
  MagnetiqIcon,
  MagnetiqLogo,
  SignoutIcon,
  DiscoverIcon,
} from "../../atoms/Icon";
import DesktopSidebarStaticNavButton from "../../atoms/sidebar/DesktopSidebarStaticButton";
import { ROUTES } from "../../../constants";

function UserSidebarContainer({ isSidebarCustomized = false }: { isSidebarCustomized?: boolean }) {
  const router = useRouter();

  const {
    setWalkthroughRun,
    walkthroughData: { run: walkThroughRunning },
    endMagnetiqProgram,
  } = useWalkthroughContext();

  const [isMagnetsMenuOpen, setIsMagnetsMenuOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isBrandsMenuOpen, setIsBrandsMenuOpen] = useState(false);
  const { userDetails } = useUserContext();
  const { brandDetails } = useBrandContext();

  const { pathname } = router.location;
  const magnetPublicProfileParams = useMatch(ROUTES.MAGNET_PAGE);

  const { sidebarExpanded: sidebarOpen, updateSideBarVisibility: setSidebarOpen } = useSideBarStore();
  const { notificationsFromBE, notifications } = useNotificationStore();
  const { isMobile } = useDeviceType();

  const { toggleSideBarVisibility } = useSideBarStore();

  const { handleUserLogout } = useUserContext();

  const handleMagnetClick = (brandId: string, magnetId: string) => {
    if (isMobile) {
      toggleSideBarVisibility();
    }
    router.go(getMagnetPageLinkWithName(brandId, magnetId));
  };

  const handleGoToUserFridge = () => {
    if (isMobile) {
      toggleSideBarVisibility();
    }
    router.go(ROUTES.USER_PROFILE_PAGE);
  };

  const handleDiscoverButtonClick = () => {
    if (isMobile) {
      toggleSideBarVisibility();
    }

    if (walkThroughRunning) {
      setWalkthroughRun(false);
      endMagnetiqProgram(
        () => router.go(ROUTES.BRANDS_DISCOVER),
        () => alert("Something went wrong, please try again later"),
      );
      return;
    }

    router.go(ROUTES.BRANDS_DISCOVER);
  };
  const handleMyMagnetsClick = () => {
    if (isSidebarCollapsed) {
      handleGoToUserFridge();
      return;
    }
    setIsMagnetsMenuOpen((prevState) => !prevState);
  };

  const handleMyBrandsClick = () => {
    setIsBrandsMenuOpen((prevState) => !prevState);
  };

  const handleBrandClick = () => {
    router.go(ROUTES.BRAND_DASHBOARD);
  };

  const handleBrandSignUpClick = () => {
    router.go(ROUTES.BRAND_ONBOARDING);
  };

  const combinedNotifications = [...notificationsFromBE, ...notifications];

  return (
    <ResusableSidebar
      isSidebarCustomized={isSidebarCustomized}
      sidebarOpen={!!sidebarOpen}
      // setSidebarOpen={() => {}}
      setSidebarOpen={() => setSidebarOpen(false)}
      topStickyItems={
        <>
          {!isSidebarCollapsed && (
            <button
              type="button"
              className="group absolute right-0 top-6 z-10 hidden w-5  items-center  rounded-s-full bg-accent text-primary transition-all duration-300 ease-in-out hover:w-6 md:flex"
              onClick={() => setIsSidebarCollapsed(true)}
              title="Collapse"
            >
              <ChevronLeft className=" h-8 w-8 transition-all" />
            </button>
          )}
          <div
            className={clsx(
              " flex items-center justify-center ",
              isSidebarCollapsed ? "my-5" : "mb-3 py-3.5 pl-4 shadow-[2px_0px_4px_rgba(89,89,89,0.5)] ",
              isSidebarCustomized ? "bg-[#2a5070]" : "bg-black",
            )}
          >
            <img
              alt={userDetails?.username}
              className={clsx("h-14 w-14 rounded-full  object-cover")}
              src={userDetails?.profile_image_url || DummyProfileImage}
            />
            {!isSidebarCollapsed && (
              <div className="ml-2 flex flex-1 flex-col truncate font-satoshi text-neutral-300">
                <p title={userDetails?.username} className="w-64 truncate  text-lg font-semibold md:w-56 2xl:text-xl">
                  {userDetails?.username}
                </p>
                {/* <p
                  className="-mt-0.5 cursor-pointer text-base font-medium opacity-60 hover:animate-pulse 2xl:-mt-1 2xl:text-xs"
                  onClick={() => {
                    linkShare(`/user-profile/${userDetails?.username}`);
                  }}
                  title="Copy to clipboard"
                >
                  {"@" + userDetails?.username + " "}
                  <ClipboardDocumentIcon className="inline-block h-3 w-3 2xl:h-4 2xl:w-4" />
                </p> */}
              </div>
            )}
          </div>
          <div className=" space-y-1 xl:space-y-2">
            <div className="discover-button">
              <DesktopSidebarStaticNavButton
                isSidebarCustomized={isSidebarCustomized}
                collapsed={isSidebarCollapsed}
                label="Discover"
                Icon={<DiscoverIcon className="h-7 w-[30px] rounded-lg bg-secondary p-1  2xl:h-9 2xl:w-9" />}
                handleClick={handleDiscoverButtonClick}
                isActive={pathname === ROUTES.BRANDS_DISCOVER}
              />
            </div>
            <div className="fridge-button">
              <DesktopSidebarStaticNavButton
                isSidebarCustomized={isSidebarCustomized}
                collapsed={isSidebarCollapsed}
                label="My Fridge"
                Icon={<FridgeIcon className="h-[26px] w-7 rounded-lg bg-secondary p-1  text-black 2xl:h-9 2xl:w-9" />}
                handleClick={handleGoToUserFridge}
                isActive={pathname === ROUTES.USER_PROFILE_PAGE}
              />
            </div>
            {/* <DesktopSidebarImageCard
                collapsed={isSidebarCollapsed}
                name="My Calender (Coming Soon)"
                image_url={CalenderIcon}
                isDisabled
                handleCardClick={workInProgressAlert}
            /> */}
            <div className="mt-3">
              <DesktopSidebarStaticNavButton
                isSidebarCustomized={isSidebarCustomized}
                collapsed={isSidebarCollapsed}
                label="My Magnets"
                Icon={<MagnetIcon className="h-7 w-8 rounded-lg bg-secondary p-0.5 2xl:h-9 2xl:w-9" />}
                handleClick={handleMyMagnetsClick}
                isActive={false}
                renderStaticIcon
                additionalClasses="border-none"
                activeIcon={
                  isMagnetsMenuOpen ? (
                    <ChevronDownIcon className="h-6 w-6 text-secondary" />
                  ) : (
                    <ChevronRightIcon className="h-6 w-6 text-secondary" />
                  )
                }
              />
              {isMagnetsMenuOpen && !isSidebarCollapsed && (
                <div className="secondary-scrollbar ml-8 mt-2 flex max-h-60 flex-col gap-y-1 overflow-y-scroll">
                  {userDetails?.inventory
                    .filter((el) => el.type === "MAGNET")
                    .map((magnet) => (
                      <DesktopSidebarImageCard
                        isSidebarCustomized={isSidebarCustomized}
                        isInteractionPending={combinedNotifications.some(
                          (notification) => notification.magnet_id === magnet.object_id && !notification.is_read,
                        )}
                        key={`sidebar_mag_card${magnet.id}`}
                        name={magnet.name}
                        isActive={
                          !!magnetPublicProfileParams && magnetPublicProfileParams.params.magnetId === magnet.object_id
                        }
                        image_url={magnet.image_url}
                        handleCardClick={() => handleMagnetClick(magnet.brand_name, magnet.name)}
                        imageAdditionalClasses="border border-white"
                      />
                    ))}
                </div>
              )}
            </div>
            {!isSidebarCollapsed && (
              <div className="mt-1">
                {brandDetails ? (
                  <>
                    <DesktopSidebarStaticNavButton
                      isSidebarCustomized={isSidebarCustomized}
                      label="My Brands"
                      collapsed={isSidebarCollapsed}
                      Icon={<BadgeIcon className="h-7 w-7 rounded-lg bg-secondary text-black 2xl:h-9 2xl:w-9" />}
                      handleClick={handleMyBrandsClick}
                      isActive={false}
                      additionalClasses="border-none"
                      renderStaticIcon
                      activeIcon={
                        isBrandsMenuOpen ? (
                          <ChevronDownIcon className="h-6 w-6 text-secondary" />
                        ) : (
                          <ChevronRightIcon className="h-6 w-6 text-secondary" />
                        )
                      }
                    />

                    {isBrandsMenuOpen && (
                      <div className="ml-10 mt-1 flex max-h-60 flex-col gap-y-2 overflow-scroll scrollbar-hide">
                        <DesktopSidebarImageCard
                          isSidebarCustomized={isSidebarCustomized}
                          key={`sidebar_mag_card${brandDetails.id}`}
                          name={brandDetails.name}
                          image_url={brandDetails.profile_image_url}
                          handleCardClick={handleBrandClick}
                          imageAdditionalClasses="border border-white"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <DesktopSidebarImageCard
                    isSidebarCustomized={isSidebarCustomized}
                    name="Brand Signup"
                    image_url={BrandIcon}
                    handleCardClick={handleBrandSignUpClick}
                  />
                )}
              </div>
            )}
            {isSidebarCollapsed && (
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  className="mt-1 h-7 w-7 rounded-full bg-accent text-primary transition-all duration-75 hover:scale-125"
                  onClick={() => setIsSidebarCollapsed(false)}
                >
                  <ChevronRight />
                </button>
              </div>
            )}
          </div>
        </>
      }
      // eslint-disable-next-line react/jsx-no-useless-fragment
      desktopNavItems={<></>}
      additionalClasses={isSidebarCollapsed ? "w-20" : "w-72 2xl:w-80"}
      bottomStickyItems={
        isMobile ? (
          <div className="">
            <button
              onClick={handleUserLogout}
              type="button"
              className={clsx("block w-full py-2  text-left text-sm text-white")}
            >
              <span className="flex items-center space-x-2 font-satoshi font-medium">
                <SignoutIcon className="h-7 w-7 rounded-lg bg-secondary p-1" color="text-primary" />
                <span>Sign out</span>
              </span>
            </button>
          </div>
        ) : isSidebarCustomized ? null : (
          <div className="my-5 flex items-center justify-center">
            {isSidebarCollapsed ? <MagnetiqIcon className="h-8 w-8" /> : <MagnetiqLogo className="w-60 2xl:w-72" />}
          </div>
        )
      }
    />
  );
}

export default UserSidebarContainer;
