/* eslint-disable react/no-danger */
import { ClaimIcon, CloseIcon } from "components/atoms/Icon";
import Modal from "components/atoms/modal";
import Button from "components/atoms/buttons/Button";
import { pluralize } from "utils/HelperFunctions";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import useCountdown from "components/hooks/useCountDown";
import moment from "moment";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { handleNotOwnedMementoDescriptionLinkClick, isHTML } from "utils";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useUserContext } from "components/context/UserContext";
import { ROUTES } from "../../../constants";

type Props = {
  claimData: ClaimCodeUserData;
  onClose: () => void;
  handleClaimButtonClick: () => void;
  disableClaimButton?: boolean;
};

function ClaimModal({ claimData, onClose, handleClaimButtonClick, disableClaimButton }: Props) {
  const { days, hours, minutes, seconds } = useCountdown(Number(claimData.end_time));
  const isCodeExpired = moment() > moment(claimData.end_time);

  const { isMagnetInUserInventory } = useUserContext();
  const isMagnetOwnedByUser = isMagnetInUserInventory(claimData.magnet_id);
  const isGatedContent = claimData.is_gated;

  const getRemainingPollTime = () => {
    if (days) return pluralize(days, "day");
    if (hours) return pluralize(hours, "hour");
    if (minutes) return pluralize(minutes, "min");
    if (seconds) return pluralize(seconds, "sec");
    return "0";
  };
  return (
    <Modal open onClose={onClose}>
      <div className="w-[calc(100vw-20px)] max-w-[600px] space-y-5 rounded-2xl bg-primary px-5 py-7 shadow-modal">
        {/* Section 1  */}
        <div className="flex justify-between">
          <div className="flex space-x-3">
            <img
              className="aspect-1 h-16 rounded-lg border border-white object-cover"
              src={claimData.magnet_image_url as string}
              alt={claimData.magnet_name}
            />
            <div className="flex flex-col font-satoshi text-white">
              <Link
                to={`/brand/${claimData.brand_name}/magnet/${claimData.magnet_name}`}
                className="text-xl font-bold leading-tight md:text-3xl"
              >
                {claimData.magnet_name}
              </Link>
              <Link to={`${ROUTES.BRAND_PUBLIC_PROFILE}/${claimData.brand_name}`} className="font-semibold opacity-70">
                {claimData.brand_name}
              </Link>
            </div>
          </div>
          <div>
            <button type="button" onClick={onClose} className="cursor-pointer p-3">
              <CloseIcon className="h-4 w-4 text-white" />
            </button>
          </div>
        </div>
        {/* Section 2 */}
        <div className="mx-auto flex w-10/12 flex-col items-center font-satoshi text-white">
          <div className="relative h-full w-full rounded-xl border border-white">
            <div
              className="absolute -right-5 -top-5 flex h-10 w-10 items-center justify-center
             rounded-full bg-accent"
            >
              <ClaimIcon className="h-6 w-6" />
            </div>
            <MagnetImageContainer image={claimData.thumbnail_url as string} altText={claimData.name} />
          </div>
          <div className="mt-4 flex self-start text-white">
            <div
              className="rounded-ll bg-accent bg-opacity-20 p-2 font-satoshi
             text-sm font-semibold leading-5"
            >
              {isCodeExpired ? "Expired" : `Expiring in ${getRemainingPollTime()}`}
            </div>
          </div>
          <span className="mt-2 self-start font-satoshi text-2xl font-bold leading-tight text-white">
            {claimData.name}
          </span>
          <span className="secondary-scrollbar my-2 h-24 self-start overflow-scroll overflow-x-hidden text-center font-satoshi text-xs font-450 text-white">
            {!isHTML(claimData.description) ? (
              <p>{claimData.description}</p>
            ) : (
              <div
                onClick={(e) => {
                  const target = e.target as HTMLElement;
                  if (target.tagName === "A") {
                    handleNotOwnedMementoDescriptionLinkClick(e, isGatedContent ?? false, isMagnetOwnedByUser ?? false);
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: `<div class="ql-container ql-bubble" style="overflow: visible; margin: 0; padding: 0;">
                        <div class="ql-editor ql-snow" style="overflow: visible; margin: 0; padding: 0;">
                          ${claimData.description}
                        </div>
                      </div>`,
                }}
              />
            )}
          </span>
        </div>
        <div className="mx-auto w-10/12 flex-col">
          <Button
            text="CLAIM"
            disabled={disableClaimButton || isCodeExpired}
            variant="magnetiqSolid"
            additionalClasses="xl:px-6 xl:py-3 xl:text-xs 2xl:text-sm 2xl:px-8 2xl:py-4"
            onClick={handleClaimButtonClick}
          />
        </div>
      </div>
      {disableClaimButton && (
        <div className="z-[100] mx-auto -mt-5 flex items-center justify-center gap-2 rounded-b-lg bg-accent p-2">
          <ExclamationTriangleIcon className="h-4 w-4 text-primary" />
          <p className="font-sora text-xxs font-semibold text-primary">Acquire the Magnet to claim this Memento</p>
        </div>
      )}
    </Modal>
  );
}

export default ClaimModal;
