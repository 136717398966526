/* eslint-disable no-console */
import { round } from "lodash";
import moment from "moment";
import { toDataURL } from "qrcode";
import { ErrorOption } from "react-hook-form/dist/types";
import toast from "react-hot-toast";
import * as yup from "yup";
import { NavigateFunction } from "react-router-dom";
import {
  GET_BRAND_ID_AND_MAGNET_ID,
  GET_COUNTRY,
  GET_STATES_BY_COUNTRY,
  MAX_FILE_UPLOAD_SIZE,
  MAX_IMAGE_UPLOAD_SIZE,
  ROUTES,
  UPLOAD_TO_S3,
  URL_VALIDATOR,
} from "../constants";
import { convertMegabytesToBytes } from "./helpers";

import { get, put } from "./request";

export type Router = {
  go: NavigateFunction;
  pathname: string;
  query: any;
  location: Location;
  history: History | null;
};

const linksToAddOrRemoveHttps = ["website_link", "instagram_link", "twitter_link", "other_link"];

export function prependHTTPsForSocialLinks(data: Record<string, string | undefined>) {
  const dataToBeMutated = { ...data };

  linksToAddOrRemoveHttps.forEach((field) => {
    // IF field has a value then.
    if (data[field]) {
      // Overwrite fields with data
      // eslint-disable-next-line operator-linebreak
      const doesFieldHaveHttpOrHttps =
        dataToBeMutated[field]?.includes("https://") || dataToBeMutated[field]?.includes("http://");

      if (!doesFieldHaveHttpOrHttps) dataToBeMutated[field] = `https://${dataToBeMutated[field]}`;
    }
  });
  return dataToBeMutated;
}
export function removeHTTPsForSocialLinks(data: Record<string, any>) {
  const dataToBeMutated = { ...data };
  linksToAddOrRemoveHttps.forEach((field) => {
    if (dataToBeMutated[field]) {
      dataToBeMutated[field] = dataToBeMutated[field].replace("https://", "");
    }
  });
  return dataToBeMutated;
}

export function workInProgressAlert() {
  toast.success("Coming Soon");
}

export const redirectToRouteWithRedirect = (route: string, preserveSearch: boolean = false) =>
  `${route}?redirectURI=${window.location.pathname}${preserveSearch ? encodeURIComponent(window.location.search) : ""}`;

export const getLoginPageURLWithRedirect = () => redirectToRouteWithRedirect(ROUTES.SIGNIN, true);

export function appendJsonToFormData(jsonData: any) {
  const formData = new FormData();
  formData.append("file", jsonData);

  return formData;
}

export function createFormDataFromJSON(jsonData: Object) {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(jsonData)) {
    formData.append(key, value);
  }
  return formData;
}

function showCopyToClipboardSuccessToast(success: boolean) {
  toast(success ? "Link copied to clipboard" : "Clipboard Not Supported by browser.", {
    position: "top-right",
  });
}

export async function linkShare(url?: string) {
  const linkToCopy = url ? `${window.location.origin}${url}` : window.location.href;

  try {
    // Share with navigator share available for mobile device only
    try {
      if (!("share" in navigator)) {
        throw new Error("navigator.share is not available");
      }

      console.log("Sharing with navigator.hare");
      await navigator.share({
        title: "Magnetiq",
        url: `${linkToCopy}`,
      });
      showCopyToClipboardSuccessToast(true);
      return;
    } catch (err) {
      console.log("Navigator share failed navigator share");
    }

    // Share with  Clipboard API
    console.log("Falling back to Clipboard API");
    try {
      if (!navigator?.clipboard) throw new Error("Navigator Clipboard is undefined");

      await navigator?.clipboard?.writeText(`${linkToCopy}`);
      showCopyToClipboardSuccessToast(true);
      return;
    } catch (err) {
      console.log("Sharing with Clipboard API Failed ");
      console.error(err);
    }

    throw new Error("Failed to copy to clipbaord");
  } catch (err) {
    console.error(err);
    showCopyToClipboardSuccessToast(false);
  }
}

export async function getCountryList(brand_id: string) {
  const country = await get(GET_COUNTRY, {
    queryParams: {
      brand_id,
    },
  });

  return country;
}

// function for getting states for a given country
export async function getAndSetStatesForCountry(
  country: string,
  setStateOptionsLoading: (stateOptionsLoading: boolean) => void,
  setAvailableStatesOptions: (availableStatesOptions: string[]) => void,
  brand_id?: string,
) {
  try {
    setStateOptionsLoading(true);
    const states = await get(GET_STATES_BY_COUNTRY, {
      queryParams: {
        country,
        ...(brand_id && { brand_id }),
      },
    });
    if (!states) {
      toast.error("Failed to get state for country");
      return;
    }
    setAvailableStatesOptions(states);
  } catch (err) {
    toast.error("Failed to load states for country");
  } finally {
    setStateOptionsLoading(false);
  }
}

// function for handling link validation
export function validateLink(val: string | undefined) {
  if (!val) return true;
  return Boolean(val?.match(URL_VALIDATOR)?.length);
}

export const validationForImage = yup
  .mixed()
  .required("Please select a file")
  .test(
    "filesize",
    `Choose an image with file size greater than 5KB and lesser than ${MAX_IMAGE_UPLOAD_SIZE} MB.`,
    (value: any) =>
      value && value.length && value[0].size < convertMegabytesToBytes(MAX_IMAGE_UPLOAD_SIZE) && value[0].size > 5120,
  );

export const validationForImageNotRequired = yup
  .mixed()
  .test(
    "filesize",
    `Image file size must be greater than 5KB and lesser than ${MAX_IMAGE_UPLOAD_SIZE} MB.`,
    (value: any, context) => {
      const uploadedImageUrlPath = `${context.path.split(".").slice(-1)}_url`;
      if (context.parent[uploadedImageUrlPath] && !value) {
        return true;
      }
      return (
        value && value.length && value[0].size < convertMegabytesToBytes(MAX_IMAGE_UPLOAD_SIZE) && value[0].size > 5120
      );
    },
  );

const getCharacterValidationError = (str: string) => `Your password must have at least 1 ${str} character`;

export const PasswordSchema = yup
  .string()
  .min(8, "Password must contain 8 or more characters with at least one of each: uppercase, lowercase and number")
  .matches(/[0-9]/, getCharacterValidationError("digit"))
  .matches(/[a-z]/, getCharacterValidationError("lowercase"))
  .matches(/[A-Z]/, getCharacterValidationError("uppercase"));

export const getImageWidthAndHeight = (imageFile: File) =>
  new Promise<{ width: number; height: number }>((resolve) => {
    const img = new Image();
    const objURL = window.URL.createObjectURL(imageFile);
    img.onload = (e) => {
      const { width, height } = e.target as HTMLImageElement;
      resolve({ width, height });
    };
    img.src = objURL;
  });

export const getVideoDuration = (video: File) =>
  new Promise<number>((resolve) => {
    const videoElement = document.createElement("video");
    const objURL = window.URL.createObjectURL(video);
    videoElement.preload = "metadata";
    videoElement.onloadedmetadata = () => {
      resolve(videoElement.duration);
    };
    videoElement.src = objURL;
  });

export function validateMedia(selectedFile: any) {
  // Check if the selected file exists
  if (!selectedFile) {
    return "Select a file.";
  }

  // Check file type based on extension
  const allowedImageTypes = ["jpg", "jpeg", "png", "bmp", "gif", "webp"];
  const allowedVideoTypes = ["mp4"];
  const allowedAudioTypes = ["mp3"];
  const allowedDocumentTypes = ["pdf", "zip"];

  const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();
  let fileType = "";

  if (fileExtension) {
    if (allowedImageTypes.includes(fileExtension) && selectedFile.type?.startsWith("image")) {
      fileType = "image";
    } else if (allowedVideoTypes.includes(fileExtension) && selectedFile.type?.startsWith("video")) {
      fileType = "video";
    } else if (allowedAudioTypes.includes(fileExtension) && selectedFile.type?.startsWith("audio")) {
      fileType = "audio";
    } else if (allowedDocumentTypes.includes(fileExtension) && selectedFile.type?.startsWith("application")) {
      fileType = "document";
    }
  }

  if (!fileType) {
    return "Invalid file type.";
  }

  // Check file size
  const fileSizeInBytes = selectedFile.size;
  const allowedFileSize: { [key: string]: { min: number; max: number } } = {
    image: { min: 5 * 1024, max: 10 * 1024 * 1024 }, // 5 KB - 10 MB
    video: { min: 1024 * 1024, max: 500 * 1024 * 1024 }, // 1 MB - 500 MB
    audio: { min: 50 * 1024, max: 100 * 1024 * 1024 }, // 50 KB - 100 MB
    document: { min: 1024, max: 50 * 1024 * 1024 }, // 1 KB - 50 MB
  };

  if (!(fileSizeInBytes >= allowedFileSize[fileType].min && fileSizeInBytes <= allowedFileSize[fileType].max)) {
    return "File size must be in given range.";
  }

  return "";
}

export const validationForThumbnail = yup
  .mixed()
  .required("Please select a thumbnail for memento")
  .test(
    "filetype, image resolution",
    "Image resolution should be atleast 200px X 200px.",
    async (file: File, context) => {
      if (file) {
        if (!file.type.startsWith("image")) {
          return context.createError({
            path: "thumbnail",
            message: "Invalid file type. Please select an image file (JPG, JPEG, PNG, GIF, WEBP)",
          });
        }
        if (file.size > 5120 && file.size > convertMegabytesToBytes(MAX_IMAGE_UPLOAD_SIZE)) {
          return context.createError({
            path: "thumbnail",
            message: "Thumbnail size should be within 5KB to 10MB",
          });
        }
        const dimensions = await getImageWidthAndHeight(file);
        if (dimensions.height !== dimensions.width) {
          return context.createError({
            path: "thumbnail",
            message: "Thumbnail chosen should have an aspect ratio of 1:1",
          });
        }
        return dimensions.width >= 200;
      }
      return false;
    },
  );

export const validationForShareRequestMedia = yup
  .mixed()
  .required("Please select a thumbnail for memento")
  .test(
    "filetype, image resolution",
    "Image resolution should be atleast 200px X 200px.",
    async (file: File, context) => {
      if (file) {
        if (
          ![
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/gif",
            "image/JPEG",
            "image/PNG",
            "image/JPG",
            "image/GIF",
            "video/mp4",
            "video/MP4",
          ].includes(file.type)
        ) {
          return context.createError({
            path: "thumbnail",
            message: "Invalid file type. Please select an image/video file, (JPG, JPEG, PNG, GIF, MP4 Supported)",
          });
        }
        if (file.type.startsWith("image")) {
          const dimensions = await getImageWidthAndHeight(file);
          return dimensions.height >= 200 && dimensions.width >= 200;
        }
        const duration = await getVideoDuration(file);
        if (duration > 140) {
          return context.createError({
            path: "thumbnail",
            message: "Please select a video shorter than 2 mins 20 secs",
          });
        }
        if (file.size) return true;
      }
      return false;
    },
  );

export const isNumber = (input: any) => !Number.isNaN(input);

function isNumeric(str: string, isZipCode = false) {
  return isZipCode
    ? // Regex for alphbets and digits
      /^[a-zA-Z0-9-]+$/.test(str)
    : // Regex for digits only
      /^\d+$/.test(str);
}

export const handleAllowWholeNumberAsInput = (event: KeyboardEvent, isZipCode = false) => {
  if (
    !isNumeric(event.key, isZipCode) &&
    event.key !== "Backspace" &&
    event.key !== "ArrowLeft" &&
    event.key !== "ArrowRight" &&
    event.key !== "Tab"
  ) {
    event.preventDefault();
  }
};

export const handlePreventSpecialCharactersInInput = (event: KeyboardEvent, allowDecimal = false) => {
  const eventKey = event.key;

  if (allowDecimal && eventKey !== "Backspace") {
    const isCharNumberOrDecimal = /[0-9]|\./.test(eventKey);
    console.log(isCharNumberOrDecimal, eventKey);
    if (!isCharNumberOrDecimal) event.preventDefault();
    return;
  }

  if (!isNumber(eventKey) && eventKey !== "Backspace") {
    event.preventDefault();
  }
};

export function formatToTwoDecimalPlaces(ip: number) {
  return round(ip, 2).toFixed(2);
}

export const uploadImageToS3 = async (file: File) => {
  if (!file) {
    toast.error("No file was selected to be uploaded.");
    return "";
  }
  if (file.size >= convertMegabytesToBytes(MAX_FILE_UPLOAD_SIZE)) {
    toast.error(`Cannot upload files greater than ${MAX_FILE_UPLOAD_SIZE} MB.`);
    return null;
  }
  const response = await put<{ file_url: string }>(UPLOAD_TO_S3, appendJsonToFormData(file));
  if (response?.file_url) {
    return response.file_url;
  }

  toast.error("Failed to upload Media");
  return null;
};

export const uploadFileToS3 = (file: File, signal?: any, onUploadProgress?: any) =>
  put<{ file_url: string }>(UPLOAD_TO_S3, appendJsonToFormData(file), {
    requestConfig: { onUploadProgress, signal },
    processError: (e) => {
      throw new Error(e);
    },
  });

// function to check whether number is float or not
function isFloat(n: number) {
  return Number(n) === n && n % 1 !== 0;
}

// function for transforming a number into comma separated string
export function formatToCommaSeparatedString(num: number, style: "currency" | "decimal") {
  return style === "currency"
    ? num.toLocaleString("en-US", {
        maximumFractionDigits: isFloat(num) ? 2 : 0,
        style,
        currency: "USD",
      })
    : num.toLocaleString("en-US", {
        style,
      });
}

export const flattenObj = (
  obj: Record<string, unknown> | Array<Record<string, unknown>>,
  parent?: string,
): Record<string, unknown> => {
  let res: Record<string, unknown> = {};

  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      res = { ...res, ...flattenObj(value as Record<string, unknown>, propName) };
    } else if (Array.isArray(value)) {
      if (typeof value[0] === "string") res[propName] = value.join(",");
      else if (typeof value[0] === "object") res = { ...res, ...flattenObj(value, propName) };
    } else {
      res[propName] = value;
    }
  }

  return res;
};

export function handleSetErrorAndSwitchFormState<FormFieldsType extends string>(
  error: CustomErrorType<FormFieldsType>,
  formFields: Record<FormFieldsType, string>,
  setError: (name: any, error: ErrorOption, options?: { shouldFocus: boolean }) => void,
): string {
  let step: string = "";
  try {
    const { error: errorObject } = error;
    // Check if there is an error Key present on the response from the backend.

    Object.keys(formFields).forEach((formFieldKey) => {
      const formFieldWithType = formFieldKey as FormFieldsType;
      if (Object.keys(errorObject).includes(formFieldWithType)) {
        step = formFields[formFieldWithType];
      }
    });

    // flatening object to get the absolute key to get errors
    const flatenErrorObject = flattenObj(errorObject);

    Object.keys(flatenErrorObject).forEach((errorKey) => {
      setError(errorKey, {
        type: "custom",
        message: flatenErrorObject[errorKey] as string,
      });
    });
  } catch (err) {
    console.log("errror parsing errors", err);
  }
  return step;
}

export function formatTimeStampToDateAndTime(createdAt: string): string {
  return `${moment(createdAt).format("MMM DD, YYYY")} at ${moment(createdAt).format("h:mma")}`;
}

export function removeCurrentBaseUrlFromUrl(url: string): string {
  // eslint-disable-next-line no-template-curly-in-string
  return url.replace(window.location.origin, "").replace("${{domain}}", "");
}
export const validationForURLPattern = (linkToValidate: string, nullable: boolean = true) => {
  const schema = yup
    .string()
    .trim()
    .test(linkToValidate, "Enter a valid url", (val: any) => validateLink(val));
  if (nullable) {
    return schema.nullable();
  }
  return schema;
};

export const pluralize = (count: number, noun: string, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const handleRedirectOrPopupActionForNotification = (
  notification: Pick<NotificationData, "action_type" | "sub_type" | "cta">,
  triggerMementoViewModal?: (mementoId: string) => void,
) => {
  if (!notification.cta) {
    throw new Error("Invalid CTA");
  }
  console.log(notification);
  switch (notification.action_type) {
    case "REDIRECT":
      return removeCurrentBaseUrlFromUrl(notification.cta);
      break;
    case "POPUP": {
      const url = new URL(notification.cta);
      const mementoId = url.searchParams.get("memento_id");
      if (mementoId && triggerMementoViewModal) {
        triggerMementoViewModal(mementoId);
        return "";
      }

      return removeCurrentBaseUrlFromUrl(notification.cta);
      break;
    }
    default:
      return removeCurrentBaseUrlFromUrl(notification.cta);
  }
};
export const validateInputImageWithExistingImageUrl = (keyToValidate: string) =>
  yup.mixed().when(keyToValidate, {
    is: (imageUrl: string) => !imageUrl,
    then: validationForImage,
    otherwise: validationForImageNotRequired,
  });

export const copyToClipboard = async (text: string) => {
  if (!navigator?.clipboard) {
    toast.error("Clipboard not supported");
    return false;
  }
  // Try to save to clipboard then save it in the state if worked
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to Clipboard");
    return true;
  } catch (error) {
    toast.error("Clipboard failed");
    return false;
  }
};

const checkOtherBrowserAndNavigate = (userAgent: string) => {
  // same for desktop as well as mobile
  if (userAgent.indexOf("Firefox") !== -1) {
    window.open("https://support.mozilla.org/en-US/kb/pop-blocker-settings-exceptions-troubleshooting", "_blank");
  } else if (userAgent.indexOf("Edge") !== -1) {
    window.open(
      "https://support.microsoft.com/en-us/microsoft-edge/block-pop-ups-in-microsoft-edge-1d8ba4f8-f385-9a0b-e944-aa47339b6bb5",
      "_blank",
    );
  } else {
    // if browser not found
    window.open("https://support.meetdapper.com/hc/en-us/articles/12523132420115-Enabling-Pop-ups", "_blank");
  }
};

export const navigateToPopUpsLink = () => {
  const { userAgent } = window.navigator;

  // For mobile devices
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // Now check user browser
    if (userAgent.indexOf("Chrome") !== -1) {
      window.open("https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DAndroid&oco=1", "_blank");
    } else if (userAgent.indexOf("Safari") !== -1) {
      window.open("https://support.meetdapper.com/hc/en-us/articles/12523132420115-Enabling-Pop-ups", "_blank");
    } else {
      // For other browsers
      checkOtherBrowserAndNavigate(userAgent);
    }
  } else {
    // For desktop devices
    if (userAgent.indexOf("Chrome") !== -1) {
      window.open("https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop&oco=1", "_blank");
      return;
    }

    if (userAgent.indexOf("Safari") !== -1) {
      window.open("https://support.apple.com/en-ca/guide/safari/sfri40696/mac", "_blank");
    } else {
      // For other browsers
      checkOtherBrowserAndNavigate(userAgent);
    }
  }
};

export const downloadQRCode = async (text: string) => {
  const canvas = document.createElement("canvas");
  const pngUrl = await toDataURL(canvas, text);
  const downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = "event-url-qr-code.png";
  downloadLink.click();
  downloadLink.remove();
  canvas.remove();
};

export function checkPrecision(number: number | undefined) {
  if (number === undefined) return true;
  // Convert the number to a string
  const numberToBeChecked = number.toString();
  // Split the number into integer and decimal parts
  const parts = numberToBeChecked.split(".");
  // If there's no decimal part, the precision is zero
  if (parts.length === 1) {
    return true;
  }
  // Get the decimal part
  const decimalPart = parts[1];
  // Check the length of the decimal part
  if (decimalPart.length <= 2) {
    return true;
  }
  return false;
}

export const handleCreateMagnetAfterModal = (magnetType: MagnetProgramTypes, navigate: NavigateFunction) => {
  const routes = {
    1: ROUTES.CREATE_CONTROLLED_MAGNET_ROUTE,
    0: ROUTES.CREATE_PUBLIC_MAGNET_ROUTE,
    2: ROUTES.CREATE_SUBSCRIPTION_MAGNET_ROUTE,
  };

  const route = routes[magnetType];

  navigate(route);
};

export function getDateSuffix(n: number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
}
export function getDaysDifference(date1Str: string, date2Str: string): number {
  function parseDate(dateStr: string): Date {
    const [month, day, year] = dateStr.split("/").map(Number);
    return new Date(2000 + year, month - 1, day);
  }
  const date1 = parseDate(date1Str);
  const date2 = parseDate(date2Str);
  const differenceInDays: number = Math.abs(date2.getTime() - date1.getTime());
  return Math.floor(differenceInDays / (1000 * 60 * 60 * 24));
}

export const getActiveSubscriptionDetailObject = (
  subscriptionDetailArray?: SubscriptionDetail[],
): SubscriptionDetail | undefined =>
  subscriptionDetailArray?.find((subscriptionDetail) => subscriptionDetail?.is_active);

export function debounce<T extends (...args: any[]) => void>(fn: T, delay: number): (...args: Parameters<T>) => void {
  let timeoutID: ReturnType<typeof setTimeout> | null = null;

  return function (...args: Parameters<T>) {
    if (timeoutID !== null) {
      clearTimeout(timeoutID);
    }

    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function handleGetMagnetFailure(errorMessage: string, error: any, router: any) {
  toast.error(errorMessage);
  if (error?.response?.status !== 200) {
    router.go(`/${ROUTES.NOT_FOUND}`, {
      replace: true,
    });
    return;
  }
  throw new Error(errorMessage);
}

export async function getIds(brandIdOrName?: string | null, magnetIdOrName?: string | null, router?: any) {
  try {
    if (brandIdOrName && magnetIdOrName) {
      const response = await get(GET_BRAND_ID_AND_MAGNET_ID, {
        queryParams: {
          brand_id_or_name: brandIdOrName,
          magnet_id_or_name: magnetIdOrName,
        },
      });
      return {
        brandId: response.brand_id,
        magnetId: response.magnet_id,
      };
    } else if (brandIdOrName) {
      const response = await get(GET_BRAND_ID_AND_MAGNET_ID, {
        queryParams: {
          brand_id_or_name: brandIdOrName,
        },
      });
      return {
        brandId: response.brand_id,
        magnetId: null,
      };
    } else if (magnetIdOrName) {
      const response = await get(GET_BRAND_ID_AND_MAGNET_ID, {
        queryParams: {
          magnet_id_or_name: magnetIdOrName,
        },
      });
      return {
        brandId: null,
        magnetId: response.magnet_id,
      };
    }
  } catch (error) {
    console.log("error", error);
    handleGetMagnetFailure("Failed to fetch IDs", error, router);
    return { brandId: null, magnetId: null };
  }
}
