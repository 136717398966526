import { useEffect, useState } from "react";
import { CloseIcon, LoadingIcon, PollIcon } from "components/atoms/Icon";
import MagnetImageContainer from "components/atoms/images/MagnetImageContainer";
import Modal from "components/atoms/modal";
import { pluralize } from "utils/HelperFunctions";
import Button from "components/atoms/buttons/Button";
import useCountdown from "components/hooks/useCountDown";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import DefaultImage from "assets/Images/BrandImage1.png";
import RefreshButton from "components/atoms/buttons/RefreshButton";
import { Link } from "react-router-dom";
import PollAnswerButton from "./PollAnswerButton";
import { ROUTES } from "../../../constants";

type Props = {
  pollData: PollDataForUser;
  isModalOpen: boolean;
  onModalClose: () => void;
  handlePollSubmit?: (pollOptionIndex: number[]) => void;
  isPollLoading: boolean;
  isBrandPollResultsModal?: boolean;
  maxVotePercentages: number[];
  handleEndPollButtonClick?: () => void;
  handleRefreshButton: () => void;
  exportPollData?: () => void;
  isLoading?: boolean;
};

function QuestionSwitcher({
  question,
  choices,
  isPollLoading,
  isBrandPollResultsModal,
  handlePollButtonClick,
  maxVotePercentage,
  userResponse,
}: {
  maxVotePercentage?: number;
  question: string;
  choices: any;
  isPollLoading: boolean;
  isBrandPollResultsModal?: boolean;
  handlePollButtonClick?: (pollOptionIndex: number) => void;
  userResponse?: number;
}) {
  return (
    <>
      <p className="mt-2 font-satoshi text-sm font-bold text-white md:text-3xl md:leading-10">{question}</p>
      <div className="relative mt-5 flex flex-col gap-y-2 md:gap-y-3">
        {isPollLoading && (
          <div
            className="absolute z-10 flex h-full w-full items-center 
            justify-center rounded-xl bg-white bg-opacity-50"
          >
            <div className="h-24 w-24">
              <LoadingIcon color="#102D4E" />
            </div>
          </div>
        )}
        {choices.map((choice: any, idx: number) => (
          <PollAnswerButton
            isBrandPollButton={isBrandPollResultsModal}
            label={choice.name}
            key={`pollOptions-${choice.name}`}
            votePercentage={choice.percentage}
            isSelectedOption={
              isBrandPollResultsModal
                ? choice.percentage === maxVotePercentage
                : choice.voted_by_user || userResponse === idx
            }
            handleOnClick={() => handlePollButtonClick?.(idx)}
            isDisabled={isBrandPollResultsModal || "voted_by_user" in choice || userResponse === idx}
          />
        ))}
      </div>
    </>
  );
}

function PollModal({
  pollData,
  isModalOpen,
  onModalClose,
  handlePollSubmit,
  isPollLoading,
  isBrandPollResultsModal,
  maxVotePercentages,
  handleEndPollButtonClick,
  handleRefreshButton,
  exportPollData,
  isLoading,
}: Props) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userResponses, setUserResponses] = useState<number[]>([]);
  const { days, hours, minutes, seconds } = useCountdown(Number(pollData.end_time));

  useEffect(() => {
    if (pollData.question.length) {
      const initialResponses = Array(pollData.question.length).fill(-1);
      setUserResponses(initialResponses);
    }
  }, [pollData.question.length]);

  const responseHandler = (questionIndex: number, answerIndex: number) => {
    const newResponses = [...userResponses];
    newResponses[questionIndex] = answerIndex;
    setUserResponses(newResponses);
    if (currentQuestion + 1 < pollData.question.length) setTimeout(() => setCurrentQuestion(currentQuestion + 1), 500);
  };

  const getRemainingPollTime = () => {
    if (days) return `${pluralize(days, "day")} left`;
    if (hours) return `${pluralize(hours, "hour")} left`;
    if (minutes) return `${pluralize(minutes, "min")} left`;
    if (seconds) return `${pluralize(seconds, "sec")} left`;
    return "ended";
  };

  return (
    <Modal open={isModalOpen} onClose={onModalClose} closeOnOutsideClick>
      <div className="w-full rounded-2xl bg-primary p-7 shadow-modal md:w-[600px]">
        <div className="flex  justify-between">
          <div className="flex space-x-3 2xl:space-x-5">
            <div className="h-16 w-16 rounded-xl border border-white md:h-20 md:w-20 2xl:h-24 2xl:w-24">
              <MagnetImageContainer
                image={pollData.magnet_image_url || DefaultImage}
                altText={pollData.magnet_name}
                brandId={pollData.brand_id}
                magnetId={pollData.magnet_id}
                magnetName={pollData.magnet_name}
                modalFunc={onModalClose}
              />
            </div>
            <div>
              <div className="flex space-x-1 2xl:space-x-2">
                <PollIcon className="h-4 w-4 2xl:h-5 2xl:w-5" />
                <p className="font-satoshi text-xxs font-semibold leading-5 text-white md:text-xs 2xl:text-sm">
                  Community Poll
                </p>
              </div>
              <Link
                to={`/brand/${pollData.brand_name}/magnet/${pollData.magnet_name}`}
                title={pollData.magnet_name}
                className="line-clamp-1 cursor-pointer overflow-clip  font-sora text-sm font-bold leading-7 text-white 2xl:text-lg"
                onClick={onModalClose}
              >
                <p className="mt-0 font-satoshi text-xs font-bold text-white md:text-xl md:leading-7 2xl:mt-2">
                  {pollData.magnet_name}
                </p>
              </Link>
              <Link
                to={`${ROUTES.BRAND_PUBLIC_PROFILE}/${pollData.brand_name}`}
                title={pollData.brand_name}
                className="line-clamp-1 cursor-pointer overflow-clip  font-sora text-sm font-bold leading-7 text-white 2xl:text-lg"
                onClick={onModalClose}
              >
                <p
                  className="mt-1 font-satoshi text-xxs font-semibold text-white
               text-opacity-70 md:leading-5 2xl:text-xs"
                >
                  {pollData.brand_name}
                </p>
              </Link>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <RefreshButton handleOnClick={handleRefreshButton} isLoading={isLoading} isDisabled={isPollLoading} />
            <button type="button" onClick={onModalClose}>
              <CloseIcon className="h-3 w-3 text-white md:h-5 md:w-5" />
            </button>
          </div>
        </div>

        {isBrandPollResultsModal && <p className="mt-5 font-sora text-sm font-medium text-secondary">RESULTS</p>}
        {pollData.question.length > 1 && (
          <>
            <div className="mt-5 flex justify-between font-satoshi font-medium text-white">
              <p className="opacity-70">
                Question {currentQuestion + 1} of {pollData.question.length}
              </p>
              <p className="flex gap-4">
                <button
                  onClick={() => {
                    if (currentQuestion - 1 >= 0) setCurrentQuestion(currentQuestion - 1);
                  }}
                  className="flex items-center gap-0.5 opacity-70 transition hover:opacity-100 active:scale-75 disabled:hidden"
                  disabled={currentQuestion === 0}
                  type="button"
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  Back
                </button>
                <button
                  onClick={() => {
                    if (currentQuestion + 1 < pollData.question.length) setCurrentQuestion(currentQuestion + 1);
                  }}
                  disabled={currentQuestion === pollData.question.length - 1}
                  className="flex items-center gap-0.5 opacity-70 transition hover:opacity-100 active:scale-75 disabled:hidden"
                  type="button"
                >
                  Next
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              </p>
            </div>

            <div className="my-2 h-1.5 w-full rounded-full bg-gray-700">
              <div
                className="h-full rounded-full bg-sky-800 transition-all duration-700"
                style={{ width: `${((currentQuestion + 1) / pollData.question.length) * 100}%` }}
              />
            </div>
          </>
        )}

        <QuestionSwitcher
          question={pollData.question[currentQuestion]}
          choices={pollData.choices[currentQuestion]}
          isPollLoading={isPollLoading}
          handlePollButtonClick={(answerIndex: number) => responseHandler(currentQuestion, answerIndex)}
          isBrandPollResultsModal={isBrandPollResultsModal}
          maxVotePercentage={maxVotePercentages[currentQuestion]}
          userResponse={userResponses[currentQuestion]}
        />

        <div className="mt-6 flex justify-between gap-x-4 font-satoshi text-base leading-5 text-white md:text-sm">
          {pollData.status === 6 ? (
            <p className="font-semibold">
              <span className="text-white text-opacity-70">Final Result : </span>
              {pluralize(pollData.total_votes, "Vote")}
            </p>
          ) : (
            <p className="font-semibold text-white">
              {pluralize(pollData.total_votes, "Vote")} &#x2022; {getRemainingPollTime()}
            </p>
          )}
          {/* <p className="font-medium text-white text-opacity-70">{formatTimeStampToDateAndTime(pollData.start_time)}</p> */}
        </div>
        {!isBrandPollResultsModal && pollData.status === 4 && maxVotePercentages[0] === -1 && (
          <div
            className="mt-6 flex justify-end gap-3"
            title={
              userResponses.filter((response) => response === -1).length
                ? "Response on each question is mandatory"
                : undefined
            }
          >
            <Button
              variant="magnetiqSolid"
              disabled={!!userResponses.filter((response) => response === -1).length}
              text="SUBMIT"
              onClick={() => handlePollSubmit?.(userResponses)}
              additionalClasses="xl:text-xs 2xl:text-sm xl:py-3 2xl:py-5 w-auto"
            />
          </div>
        )}
        <div className="mt-6 flex justify-end gap-3">
          {isBrandPollResultsModal && pollData.status !== 6 && (
            <Button
              variant="whiteOutline"
              text="END POLL"
              onClick={handleEndPollButtonClick}
              additionalClasses="xl:text-xs 2xl:text-sm xl:py-2 2xl:py-3 w-1/2"
            />
          )}
          {isBrandPollResultsModal && (
            <Button
              variant="magnetiqSolid"
              text="DOWNLOAD"
              onClick={exportPollData}
              additionalClasses="xl:text-xs 2xl:text-sm xl:py-3 2xl:py-5 w-1/2"
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default PollModal;
